var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-403" }, [
    _c("img", {
      attrs: {
        src: require("@/assets/illustration-erro.svg"),
        alt: "403 image"
      }
    }),
    _vm._m(0),
    _c("p", { staticClass: "description" }, [
      _vm._v("Você não tem autorização para ver esta página.")
    ]),
    _c("div", { staticClass: "wrap-buttons" }, [
      _c("a", {
        staticClass: "tui tuim ui basic large fluid button",
        attrs: {
          href: "https://trackmob.octadesk.com/login",
          target: "_blank"
        },
        domProps: { innerHTML: _vm._s(_vm.$t("SpeakWithSupport")) }
      }),
      _c("a", {
        staticClass: "tui tuim ui primary large fluid button",
        domProps: { innerHTML: _vm._s(_vm.$t("Back")) },
        on: {
          click: function($event) {
            return _vm.$router.go(-1)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "title" }, [
      _c("span", { staticClass: "error" }, [_vm._v("403")]),
      _vm._v(" Forbidden.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }